import { useState } from 'react'

import cn from 'classnames'
import parse from 'html-react-parser'
import { copyTextToClipboard, tr } from 'mmfintech-commons'

import { PayIdPaneStyled } from './PreviewInstructions.styled'

import { PayIdInstructionsResult } from 'mmfintech-commons-types'

import CopyFull from '../../../assets/icons/copy-blue.svg'
import CopyEmpty from '../../../assets/icons/copy.svg'

export const PreviewPayId = ({ result }) => {
  const { payCode, processingAmount, processingCurrency } = result as PayIdInstructionsResult

  const [copiedId, setCopiedId] = useState(false)
  const [copiedAmount, setCopiedAmount] = useState(false)

  const handleCopyIdClick = () => {
    const text = payCode?.text
    if (typeof text === 'string' && text.length) {
      copyTextToClipboard(text, () => {
        setCopiedId(true)
        setCopiedAmount(false)
      })
    }
  }

  const handleCopyAmountClick = () => {
    const text = String(processingAmount)
    if (typeof text === 'string' && text.length) {
      copyTextToClipboard(text, () => {
        setCopiedAmount(true)
        setCopiedId(false)
      })
    }
  }

  return (
    <PayIdPaneStyled>
      <div className='caption'>{tr('CHECKOUT.PAY_ID.HEADING', 'Pay with PayID')}</div>

      <div className='static-label'>{tr('CHECKOUT.PAY_ID.TITLE', 'Your PayID for this transaction')}</div>
      <div className={cn('static', { copied: copiedId, 'mb-1': !!processingCurrency && !!processingAmount })}>
        <span data-test='pay-id'>{payCode?.text}</span>
        <span className='copy-button' onClick={handleCopyIdClick}>
          <img src={copiedId ? CopyFull : CopyEmpty} alt='' />
        </span>
      </div>

      {!!processingCurrency && !!processingAmount ? (
        <>
          <div className='static-label'>{tr('CHECKOUT.PAY_ID.AMOUNT', 'Your PayID transfer amount')}</div>
          <div className={cn('static', { copied: copiedAmount })}>
            <span data-test='processing-amount'>
              {processingAmount} {processingCurrency}
            </span>
            <span className='copy-button' onClick={handleCopyAmountClick}>
              <img src={copiedAmount ? CopyFull : CopyEmpty} alt='' />
            </span>
          </div>
        </>
      ) : null}

      <div className='bullets'>
        <div className='bullet'>
          <div className='number'>1</div>
          {tr('CHECKOUT.PAY_ID.STEP1', 'Copy the PayID mentioned in this page.')}
        </div>
        <div className='bullet'>
          <div className='number'>2</div>
          {tr('CHECKOUT.PAY_ID.STEP2', 'Open your mobile banking App and select NPP funds transfer.')}
        </div>
        <div className='bullet'>
          <div className='number'>3</div>
          {tr('CHECKOUT.PAY_ID.STEP3', 'Transfer the funds to the PayID copied in step 1.')}
        </div>
        <div className='bullet'>
          <div className='number'>4</div>
          <div>
            {parse(
              tr(
                'CHECKOUT.PAY_ID.STEP4',
                'Please enter the <b>exact</b> amount specified to ensure your transaction to be processed successfully.'
              )
            )}
          </div>
        </div>
      </div>
    </PayIdPaneStyled>
  )
}
