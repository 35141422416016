import styled from 'styled-components'

export const CheckoutHeader = styled.div`
  width: 100%;
  border-radius: 12px 12px 0 0;
  background: #55419b;
  margin-top: -6.7rem;
  //background: #1b529c;
  //border-bottom: 10px solid #1d157d;
  //border-radius: 0.8rem 0.8rem 0 0;
  //width: 100%;
  padding: 3rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;

  color: white;
  // color: #b3d1f6;
  font-family: 'Barlow', sans-serif;
  font-size: 1.5rem;

  .left {
    display: flex;
    align-items: flex-start;

    .amount {
      //color: #ffffff;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 100%;
    }
    .currency {
      //color: #b3d1f6;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
  .right {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
  }

  @media (max-width: 480px) {
    border-radius: 0;
  }
`

export const CheckoutBody = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  min-height: 15rem;
  position: relative;

  .back-button {
    display: inline-flex;
    position: absolute;
    top: 2rem;
    left: 2rem;
    cursor: pointer;
  }

  .title {
    color: #454a54;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.1rem;
    text-align: center;

    padding: 1rem 2rem;
    @media (min-width: 500px) {
      padding: 2rem 4rem;
    }
  }

  .label {
    width: 100%;
    padding: 2rem 3rem;
    text-transform: uppercase;
    color: #6e7b8f;
    font-size: 1.4rem;
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0;
  }

  .search-container {
    padding: 1rem 2rem 0;

    @media (min-width: 500px) {
      padding: 0 4rem;
    }
  }
`

export const CheckoutButtonsWrapper = styled.div`
  padding: 0 2rem 1rem;
  @media (min-width: 500px) {
    padding: 0 4rem 2rem;
  }

  .button {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const CheckoutInputWrapper = styled.div`
  border-bottom: none;
  padding: 0 2rem 1rem;

  @media (min-width: 500px) {
    padding: 0 4rem 1rem;
  }

  p {
    font-size: 1.2rem;
  }
`

export const CheckoutFooter = styled.div`
  background: #687b99;
  border-radius: 0 0 0.8rem 0.8rem;
  cursor: pointer;
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 500;

  &.secondary {
    background: #6996fb;
  }

  &.disabled {
    cursor: default;
    color: #808080;
  }

  @media (max-width: 480px) {
    border-radius: 0;
  }
`

export const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0 1rem;
  width: 100%;

  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  a {
    color: #8f99a3;
    text-decoration: none;

    &:hover {
      color: #000000;
    }
  }
`

export const ProblemWrapper = styled.div`
  margin: 3rem 0 1rem;
  text-align: center;

  span {
    cursor: pointer;
    color: #a3b1bf;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
