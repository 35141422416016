import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { BackIcon } from '../../icons'
import { ButtonsFooter } from './ButtonsFooter'
import { DepositResult } from '../DepositResult'
import { SelectBankStep } from './SelectBankStep'
import { AdditionalFieldsStep } from './AdditionalFieldsStep'
import { InternalExecutionStep } from './InternalExecutionStep'
import { ErrorDisplay, Input, Preloader } from 'mmfintech-portal-commons'
import { CheckoutHeader, CheckoutBody, ProblemWrapper, CheckoutInputWrapper } from './Checkout.styled'
import { CheckoutContainer, CheckoutHeaderBack, CheckoutWrapper, LanguageMenu, SelectMethod } from '../../components'

import settings from '../../settings'
import { GlobalContext, isValidFunction, tr } from 'mmfintech-commons'
import { actions, CheckoutFlowStep, useCheckout, useLanguageParam, useSessionId } from 'mmfintech-checkout-commons'

import { ThunkDispatch } from 'redux-thunk'
import { CountryChoice, PaymentMethodEnum, PaymentSessionStatusEnum, SupportedBank } from 'mmfintech-commons-types'
import { WarningMessageWrapper } from '../DepositResult/PreviewBankwire/PreviewBankwire.styled'

export const Checkout = () => {
  const { modalShow } = useContext(GlobalContext)

  useLanguageParam(settings.languages)
  const { sessionId } = useSessionId()

  const [triggered, setTriggered] = useState(false)

  const {
    banks,
    banksCount,
    checkoutCountries,
    checkoutError,
    checkoutOptionsFetching,
    checkoutPay,
    checkoutPayFetching,
    session,
    sessionFetching,
    selectedLanguage
  } = useSelector(
    ({
      common: { selectedLanguage },
      checkout: {
        banks,
        banksCount,
        checkoutCountries,
        checkoutError,
        checkoutOptionsFetching,
        checkoutPay,
        checkoutPayFetching,
        session,
        sessionFetching
      }
    }: any) => ({
      banks,
      banksCount,
      checkoutCountries,
      checkoutError,
      checkoutOptionsFetching,
      checkoutPay,
      checkoutPayFetching,
      selectedLanguage,
      session,
      sessionFetching
    }),
    shallowEqual
  )

  const {
    amount,
    currency,
    sessionStatus,
    paymentResult,
    paymentResultType,
    processingAmount,
    processingCurrency,
    showAmount,
    showCurrency,
    showCancelButton
  } = session || {}

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const {
    step,
    fields,
    setStep,
    logEvent,
    handlePay,
    formValues,
    countryCode,
    initialized,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    selectedPaymentOption,
    hasBankSelection,
    hasAdditionalFields,
    showFieldsAfterBanks
  } = useCheckout({
    initialStep: CheckoutFlowStep.SELECT_METHOD,
    enableLogging: true,
    preselectSingleBank: true,
    preselectSingleMethod: true
  })

  const hasBankField = Array.isArray(fields) ? !!fields.find(node => node.name === 'bankChoiceId') : false

  const isInterac = () => paymentMethod === 'INTERAC'
  const isBankSelected = () => !hasBankField || (hasBankField && formValues.getValue('bankChoiceId')) || isInterac()

  const isCountrySelectionVisible = () =>
    Array.isArray(checkoutCountries) &&
    checkoutCountries.length > 1 &&
    !(session?.country && checkoutCountries.find(c => c.countryCode === session.country))

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleLanguageChanged = (code: string) => {
    void dispatch(actions.common.storeSelectedLanguage(sessionId, code))
  }

  const handleSubmit = () => {
    if (paymentMethod && isBankSelected() && !checkoutPayFetching) {
      handlePay()
    }
  }

  const handleContinueAfterBankSelection = () => {
    if (showFieldsAfterBanks() && hasAdditionalFields()) {
      setStep(CheckoutFlowStep.ADDITIONAL_FIELDS)
      return
    }
    if (formValues.areValid()) {
      handleSubmit()
    } else {
      scrollToTop()
    }
  }

  const handleContinueAfterAdditionalFields = () => {
    if (!showFieldsAfterBanks() && hasBankSelection()) {
      setStep(CheckoutFlowStep.SELECT_BANK)
      return
    }
    handleSubmit()
  }

  const prepareBankName = () => {
    const code = formValues.getValue('bankChoiceId')
    const selectedBank = banks?.find((bank: SupportedBank) => bank.bankChoiceId === code)
    if (selectedBank) {
      return tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay with {{METHOD}}', {
        METHOD:
          selectedLanguage == 'ko' && countryCode === 'KOR'
            ? selectedBank.name || selectedBank.nameEnglish
            : selectedBank.nameEnglish || selectedBank.name
      })
    }
    return tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay with {{METHOD}}', {
      METHOD: tr('METADATA.PAYMENT_OPTIONS.' + paymentMethod, paymentMethod)
    })
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const validBankSelected = () => {
    const exists = hasBankSelection()
    return !exists || (exists && !!formValues.getValue('bankChoiceId'))
  }

  const isFirstBankwireInKorea = () =>
    selectedPaymentOption &&
    selectedPaymentOption.paymentMethod === PaymentMethodEnum.BANKWIRE &&
    countryCode === 'KOR' &&
    !selectedPaymentOption.tokenizedBankAccount

  const isSecondBankwireInKorea = () =>
    selectedPaymentOption &&
    selectedPaymentOption.paymentMethod === PaymentMethodEnum.BANKWIRE &&
    countryCode === 'KOR' &&
    !!selectedPaymentOption.tokenizedBankAccount

  useEffect(() => {
    return () => {
      setTriggered(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (initialized) {
      if (
        paymentMethod &&
        formValues.getValue('bankChoiceId') &&
        banksCount === 1 &&
        !triggered &&
        !checkoutPayFetching
      ) {
        setTriggered(true)
        handlePay()
      }
    }
    // eslint-disable-next-line
  }, [initialized, formValues.getValue('bankChoiceId')])

  useEffect(() => {
    if (checkoutError) {
      scrollToTop()
    }
  }, [checkoutError])

  return (
    <CheckoutWrapper>
      <CheckoutContainer>
        <CheckoutHeaderBack />

        <CheckoutHeader>
          <div className='left'>
            <span className='amount'>{showAmount || amount}</span>
            <span className='currency'>{showCurrency || currency}</span>
          </div>
          <div className='right'>
            <LanguageMenu onChange={handleLanguageChanged} />
          </div>
        </CheckoutHeader>

        {sessionFetching || !initialized || (triggered && step !== CheckoutFlowStep.DEPOSIT_RESULT) ? (
          <CheckoutBody>
            <Preloader />
          </CheckoutBody>
        ) : (
          <>
            {step === CheckoutFlowStep.SELECT_BANK ||
            step === CheckoutFlowStep.SELECT_METHOD ||
            (step === CheckoutFlowStep.ADDITIONAL_FIELDS && !showFieldsAfterBanks()) ? (
              <CheckoutBody>
                <ErrorDisplay error={[checkoutError]} />

                {isCountrySelectionVisible() ? (
                  <CheckoutInputWrapper className='mt-3'>
                    <Input
                      type='select'
                      name='countryCode'
                      label={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
                      options={checkoutCountries?.map((country: CountryChoice) => ({
                        value: country.countryCode,
                        label: country.name
                      }))}
                      value={countryCode}
                      onChange={(_name: string, value: string) => setCountryCode(value)}
                      hideErrorLine
                      data-test='bank-search-input'
                    />
                  </CheckoutInputWrapper>
                ) : (
                  <div className='mb-2' />
                )}

                <SelectMethod
                  method={paymentMethod}
                  setMethod={setPaymentMethod}
                  errorVisible={countryCode && !checkoutOptionsFetching}
                />

                {isFirstBankwireInKorea() ? (
                  <CheckoutInputWrapper>
                    <WarningMessageWrapper>
                      <p>
                        {tr(
                          'CHECKOUT.BANKWIRE.KOREA_WARNING1',
                          'Please note that the first time, the payment process may take a little longer than usual while we verify your bank account. Future transactions will be faster.'
                        )}
                      </p>
                      <p>
                        {tr(
                          'CHECKOUT.BANKWIRE.KOREA_WARNING2',
                          'Please make sure to enter the below bank account information exactly as it appears in your online banking, or your transaction may fail.'
                        )}
                      </p>
                    </WarningMessageWrapper>
                  </CheckoutInputWrapper>
                ) : null}

                {isSecondBankwireInKorea() ? (
                  <div className='title'>
                    {tr('CHECKOUT.BANKWIRE.PAY_WITH_LAST_ACCOUNT', 'Pay with {{BANK_NAME}} {{ACCOUNT_NUMBER}}', {
                      BANK_NAME: selectedPaymentOption?.tokenizedBankAccount?.bankName || '',
                      ACCOUNT_NUMBER: selectedPaymentOption?.tokenizedBankAccount?.accountNumber || '****'
                    })}
                  </div>
                ) : null}

                {!showFieldsAfterBanks() && hasAdditionalFields() && !isSecondBankwireInKorea() ? (
                  <>
                    <AdditionalFieldsStep
                      countryCode={countryCode}
                      paymentMethod={paymentMethod}
                      fields={fields}
                      formValues={formValues}
                    />
                  </>
                ) : null}

                {hasBankSelection() && !isSecondBankwireInKorea() ? (
                  <>
                    {!showFieldsAfterBanks() && hasAdditionalFields() ? (
                      <div className='title'>{tr('CHECKOUT.PAYMENT.BANK', 'Bank')}</div>
                    ) : null}
                    <SelectBankStep
                      countryCode={countryCode}
                      formValues={formValues}
                      onSubmit={handleContinueAfterBankSelection}
                      logEvent={logEvent}
                    />
                  </>
                ) : null}

                <ButtonsFooter
                  disabled={
                    !paymentMethod || (!validBankSelected() && !isSecondBankwireInKorea()) || checkoutPayFetching
                  }
                  onSubmit={() => {
                    if (isSecondBankwireInKorea()) {
                      handleContinueAfterAdditionalFields()
                    } else {
                      handleContinueAfterBankSelection()
                    }
                  }}
                  onCancel={handleCancelClick}
                  showCancel={showCancelButton}
                />
              </CheckoutBody>
            ) : null}

            {step === CheckoutFlowStep.ADDITIONAL_FIELDS && showFieldsAfterBanks() && !isSecondBankwireInKorea() ? (
              <CheckoutBody>
                <span className='back-button' onClick={() => setStep(CheckoutFlowStep.SELECT_BANK)}>
                  <BackIcon />
                </span>

                {paymentMethod ? (
                  <div className='title' data-test={`pay-with-${paymentMethod}-fields`}>
                    {prepareBankName()}
                  </div>
                ) : null}

                <ErrorDisplay error={[checkoutError]} />

                <AdditionalFieldsStep
                  countryCode={countryCode}
                  paymentMethod={paymentMethod}
                  fields={fields}
                  formValues={formValues}
                />

                <ButtonsFooter
                  disabled={checkoutPayFetching}
                  onSubmit={handleContinueAfterAdditionalFields}
                  onCancel={handleCancelClick}
                  showCancel={showCancelButton}
                />
              </CheckoutBody>
            ) : null}

            {step === CheckoutFlowStep.INTERNAL_EXECUTION ? <InternalExecutionStep /> : null}

            {step === CheckoutFlowStep.DEPOSIT_RESULT ? (
              <DepositResult response={prepareResponse()} logEvent={logEvent} session={session} sessionId={sessionId} />
            ) : null}

            {isValidFunction(modalShow) && sessionId ? (
              <ProblemWrapper>
                <span onClick={() => modalShow(null)} data-test='report-a-problem-button'>
                  {tr('CHECKOUT.PROBLEM.TITLE', 'Report a Problem')}
                </span>
              </ProblemWrapper>
            ) : null}
          </>
        )}
      </CheckoutContainer>
    </CheckoutWrapper>
  )
}
